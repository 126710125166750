import React, { useState, useEffect } from "react";
import { Html } from "@react-three/drei";
import { useAxes } from "../hooks/Axes";

function ToyDetail(props) {
  const { data, handleOpenOverlay } = props;
  const [v, e] = useAxes();

  const rotation = data?.appear?.rotation;
  const position = data?.appear?.position;
  const size = data?.appear?.size;

  return (
    <>
      <Html
        key={Math.random() * 1000000}
        rotation={e(rotation?.pitch, rotation?.yaw, rotation?.roll)}
        position={v(
          position?.farness,
          position?.height,
          position?.how_far_right
        )}
        wrapperClass={"will-change "}
        as="div"
        zIndexRange={[10000, 0]}
        center
        transform
        sprite={props.alwaysLookAtCamera}
        style={{
          display: `${props.show ? "inline" : "none"}`,
        }}
      >
        {/* <a href={props.link}> */}
        <img
          src={data?.image}
          width={size?.width}
          height={size?.height}
          className={`max-w-[${props.w}px] max-h-[${props.h}px]`}
          alt=""
          // onClick={props.onClick}
          onClick={() => handleOpenOverlay(data)}
        />
        {/* </a> */}
      </Html>
    </>
  );
}
export default ToyDetail;
