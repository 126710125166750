import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useImage } from "../../hooks/Image";
import { PencilIcon } from "@heroicons/react/24/outline";
import GachaItem from "./GachaItem";
import TextInput from "../../common/form/TextInput";
import TextareaInput from "../../common/form/TextareaInput";
import StagePreview from "./preview/StagePreview";
import ARPreview from "./preview/ARPreview";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function StageSettingItem(props) {
  const navigate = useNavigate();
  const { data } = props;

  const [title, setTitle] = useState("");
  const [catchphrase, setCatchphrase] = useState("");
  const [eyecatchImage, seteyecatchImage] = useState("");
  const [place, setPlace] = useState("");
  const [time, setTime] = useState("");
  const [detail, setDetail] = useState("");

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (data) {
      setTitle(data.name);
      setCatchphrase(data.catchphrase);
      setPlace(data.place?.name);
      // setTime();
      setDetail(data.instruction);
      seteyecatchImage(data.eyecatch_image);
    }
  }, [data]);

  const handleSelect = (ranges) => {
    console.log(ranges);
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
  };

  // useEffect(() => {

  // }, [title, catchphrase, place, time, detail]);

  return (
    <>
      <div className="grid grid-cols-1 gap-4 mb-12" id={data?.id}>
        <h2 className="text-w-800 text-lg">{catchphrase}</h2>

        <div className="stage-setting ml-16">
          <h2 className="text-w-800 text-lg ">イベント設定</h2>

          <div className="grid grid-cols-3 gap-4 ml-16 mt-4">
            <div className="col-span-1 gap-4">
              <div className="gacha-item">
                <TextInput
                  label="タイトル"
                  icon={<PencilIcon className="h-6 w-6" />}
                  name="title"
                  value={title}
                  setValue={setTitle}
                  iconOnEnd
                />

                <TextInput
                  label="キャッチフレーズ"
                  icon={<PencilIcon className="h-6 w-6" />}
                  name="catchphrase"
                  value={catchphrase}
                  setValue={setCatchphrase}
                  iconOnEnd
                />

                <TextInput
                  label="場所"
                  icon={<PencilIcon className="h-6 w-6" />}
                  name="place"
                  value={place}
                  setValue={setPlace}
                  iconOnEnd
                />
                <>
                  <label>日時</label>
                  <div className="form-group p-end">
                    <span class="input-group-addon">
                      <PencilIcon className="h-6 w-6" />
                    </span>
                    <DatePicker
                      className="form-control"
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      // isClearable={true}
                    />
                  </div>
                </>

                <TextareaInput
                  label="詳細"
                  icon={<PencilIcon className="h-6 w-6" />}
                  name="detail"
                  value={detail}
                  setValue={setDetail}
                  iconOnEnd
                />
              </div>

              <div className="post-container mt-4">
                <StagePreview
                  title={title}
                  catchphrase={catchphrase}
                  place={place}
                  time={time}
                  detail={detail}
                  eyecatchImage={eyecatchImage}
                />
              </div>
            </div>
            <div className="col-span-1 gap-4">
              <div className="gacha-item ar-preview">
                <ARPreview
                  openPost={true}
                  title={title}
                  catchphrase={catchphrase}
                  place={place}
                  time={time}
                  detail={detail}
                  eyecatchImage={eyecatchImage}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="gacha-containter ml-16">
          {data != null &&
            data.toys.map((toy) => <GachaItem data={toy} stageTitle={title} />)}
        </div>
      </div>
    </>
  );
}
export default StageSettingItem;
