import { usePolarPosition } from "./PolarPosition";

const randomize = (min, max) => Math.random() * (max - min) + min;

export const useRandomPosition = () => {
  const polarPosition = usePolarPosition();
  const randomPosition = (
    distance = 170, // 視点からの距離
    minPhi = -180, // 正面から左方向に真後ろまで
    maxPhi = 180, // 正面から右方向に真後ろまで
    minTheta = -180, // 下に見下ろす最大
    maxTheta = 180 // 上に見上げる最大
  ) => {
    const theta = randomize(minTheta, maxTheta);
    const phi = randomize(minPhi, maxPhi);
    return polarPosition(distance, theta, phi);
  };
  return randomPosition;
};
