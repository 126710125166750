import { memo, React, useCallback, useState } from "react";
import { Html } from "@react-three/drei";
import * as THREE from "three";

function Treasure(props) {
  const [clicked, setClicked] = useState(false);
  const d2r = (d) => d * (Math.PI / 180);
  const v = (x, y, z) => {
    return /iP(hone|(o|a)d)/.test(navigator.userAgent)
      ? new THREE.Vector3(z, y, -x)
      : new THREE.Vector3(x, y, z);
  };
  const e = (rx, ry, rz) => {
    return /iP(hone|(o|a)d)/.test(navigator.userAgent)
      ? new THREE.Euler(d2r(rx), d2r(ry), d2r(rz))
      : new THREE.Euler(d2r(rx), d2r(ry - 90), d2r(rz));
  };
  const onClick = useCallback(() => {
    if (clicked) return;
    setClicked(true);
  }, []);
  return (
    <Html
      key={Math.random() * 1000000}
      rotation={e(props.pitch, props.yaw, props.roll)}
      position={v(props.farness, props.height, props.how_far_right)}
      wrapperClass={"will-change "}
      as="div"
      zIndexRange={[10000, 0]}
      center
      transform
      sprite={props.alwaysLookAtCamera}
      style={{ display: `${props.show ? "inline" : "none"}` }}
    >
      {/* ÷<PopIn tension={3000} friccion={1} mass={1}> */}
      {clicked ? (
        <img
          src={props.iContent}
          width={props.wC}
          height={props.hC}
          className={`max-w-[${props.wC}px] max-h-[${props.hC}px]`}
          alt=""
        />
      ) : (
        <img
          src={props.iBox}
          width={props.wB}
          height={props.hB}
          className={`max-w-[${props.wB}px] max-h-[${props.hB}px]`}
          onClick={onClick}
          alt=""
        />
      )}
      {/* </PopIn> */}
    </Html>
  );
}
export default memo(Treasure);
