import { React, memo } from "react";
import Webcam from "react-webcam";
import GraffitiCanvas from "./GraffitiCanvas";
import { EventEmitter } from "fbemitter";
import HUD from "./hud/HUD";
import Navigation from "./hud/Navigation";

function MRRootView() {
  const emitter = new EventEmitter();
  return (
    <>
      <div
        className="z-1"
        // style={{ background: "black" }}
      >
        <div className="w-[100vw] h-[100vh] z-1">
          {/* <HUD /> */}

          <Webcam
            className="w-full h-full absolute object-cover min-h-[100vh] min-w-[100vw]"
            audio={false}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              facingMode: { exact: "environment" },
            }}
            muted={true}
          />
          <GraffitiCanvas  />
        </div>
      </div>
    </>
  );
}
export default memo(MRRootView);
export const mainViewURL = "/world-scanner";
