
import {React, memo, useState, useCallback }  from 'react'
import {Html} from '@react-three/drei';
import * as THREE from 'three'
import YouTube from 'react-youtube';
import { useAxes } from "../hooks/Axes";

function InstagramEmbed(props) {

    const { data, handleOpenOverlay } = props;
    const [v, e] = useAxes();
  
    const rotation = data?.appear?.rotation;
    const position = data?.appear?.position;
    const size = data?.appear?.size;

    const [isLite, setIsLite] = useState(true);
    const onClick=useCallback(e => {
        setIsLite(false)
    }, []);
    const on_ready = useCallback ((e) => {
        console.log("ready : ", e)
        e.target.playVideo();
    }, []);
    const on_state_change = useCallback ((e) => {
        console.log("state change : ", e)
    }, []);
    const on_error = useCallback ((e) => {
        console.error("error : ", e);
    }, []);
    const opts = {
        height: size.width.toString(),
        width: size.height.toString(),
        playerVars: data?.player,
    }
    
    return (
      <Html
        key={Math.random() * 1000000}
        rotation={e(rotation?.pitch, rotation?.yaw, rotation?.roll)}
        position={v(
          position?.farness,
          position?.height,
          position?.how_far_right
        )}
        wrapperClass={"will-change"}
        as="div"
        zIndexRange={[10000, 0]}
        center
        transform={false}
        sprite={false}
      >
      <div dangerouslySetInnerHTML={{__html : data?.embedcode}}></div>
      </Html>
    );
}
export default memo(InstagramEmbed);