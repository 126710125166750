
import a from './particle_images/2e980be2303b1d2a1aa17c8c8ccabaf6.gif'
import b from './particle_images/590903_8cb501c5527743b3bc63d6aa09c0efc1_mv2.gif'
import c from './particle_images/AW4046214_07.gif'
import d from './particle_images/bat.gif'
import e from './particle_images/fly.gif'
import f from './particle_images/jack-o-lantern-2-pumpkin-funny-unscreen.gif'
import g from './particle_images/jack-o-lantern-33-nodding-jack-unscreen.gif'
import h from './particle_images/jack-o-lantern-46-funny-jack-o-unscreen.gif'
import i from './particle_images/jack-o-lantern-54-jack-o-lantern-transparent-background.gif'
import j from './particle_images/kis1029l-unscreen.gif'
import k from './particle_images/TiredHighIndusriverdolphin-mob-unscreen.gif'
import SimpleImageAdsense from './SimpleImageAdsense'
import {useRandomPosition} from "../hooks/RandomPosition"

export default function Particle(props) {

    const particle_images = [a,b,c,d,e,f,g,h,i,j,k]
    const position = useRandomPosition()
    const particle_count = 100
    return (
        <>
            {[...new Array(particle_count)].map(() => (
                <SimpleImageAdsense
                {...position()}
                onClick={() => (window.location.href = "https://careerblocks.jp/")}
                alwaysLookAtCamera={false}
                show
                image={particle_images[Math.floor(Math.random() * particle_images.length)]}
                w={1280}
                h={720}
                />
                ))}
        </>
    )
}