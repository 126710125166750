
import {React, memo, useState, useCallback }  from 'react'
import {Html} from '@react-three/drei';
import * as THREE from 'three'
import YouTube from 'react-youtube';
import { useAxes } from "../hooks/Axes";

function TwitterEmbed(props) {

    const { data, handleOpenOverlay } = props;
    const [v, e] = useAxes();
  
    const rotation = data?.appear?.rotation;
    const position = data?.appear?.position;
    const size = data?.appear?.size;

    return (
      <Html
        key={Math.random() * 1000000}
        rotation={e(rotation?.pitch, rotation?.yaw, rotation?.roll)}
        position={v(
          position?.farness,
          position?.height,
          position?.how_far_right
        )}
        wrapperClass={"will-change"}
        as="div"
        zIndexRange={[10000, 0]}
        center
        transform={false}
        sprite={false}
      >
        <iframe height="300px" srcDoc='<a class="twitter-timeline" href="https://twitter.com/Oh_No_Mai?ref_src=twsrc%5Etfw">Tweets by Oh_No_Mai</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>'></iframe>
      </Html>
    );
}
export default memo(TwitterEmbed);