import { memo } from "react";

import footer from "./images/peta_bottom.png";
import header from "./images/peta_top.png";

const Decorator = (props) => {
  return (
    <>
      {/* B header */}
      <div className="absolute top z-[3000000] inset-x-0 m-auto flex justify-top">
        <img src={header} alt="" className="w-full" />
      </div>

      {/* A footer */}
      <div className="absolute bottom-[38px] z-[3000000] inset-x-0 m-auto flex justify-bottom">
        <img src={footer} alt="" className="w-full" />
      </div>
    </>
  );
};

export default memo(Decorator);
