import { useState, useEffect } from "react";
import { usePosition } from "../../../hooks/Position";
import HUD from "../../../hud/HUD";
import arPreviewImg from "../../../image/post/ar-preview.png";

function ARPreview(props) {
  const { title, place, time, detail, eyecatchImage, openPost, catchphrase } =
    props;

  const [event, setEvent] = useState(null);
  const [position, getCurrent] = usePosition();
  return (
    <div className="brg-image h-100">
      <img src={arPreviewImg} />
      {openPost ? (
        <div className="brg-image h-100">
          <div className="post-layer">
            <div className="brg-layer" />
            <div className="post-content">
              <div className="title">{catchphrase}</div>
              <div className="sub-title text-right">{title}</div>
              <p>{detail}</p>
              <div className="hashtag">
                #プロモーション大作戦 #アイドル #地下アイドル
              </div>
              <div className="location">{place}</div>
              <div className="time">{time}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="hub-action">
          <div className="text-sm text-w-800 post-title">{catchphrase}</div>
        </div>
      )}

      <HUD
        positioning={position.active}
        display_object_count={5}
        movie_object_count={5}
        onPositionUpdate={() => getCurrent()}
        event={event}
      />
    </div>
  );
}
export default ARPreview;
