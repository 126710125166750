import React from "react";
import Modal from "../common/Modal";
import gif from "../image/fate-summon.gif";
import { useNavigate } from "react-router-dom";
import { useImage } from "../hooks/Image";

function ToyDetalModal(props) {
  const { toy, gacha, loading, open, setOpen, toyId } = props;
  const navigate = useNavigate();

  const { error, image } = useImage("rarity_frame/" + toy?.rank + ".png");

  return (
    <Modal
      className="toy-detail-modal"
      open={open}
      onClose={() => setOpen(false)}
      content={
        <>
          {loading && <img className="loader" src={gif} alt="loading..." />}

          <div className="toy-content z-[300000]">
            <div className="toy-body">
              <h1 className="title">{toy?.name}</h1>
            </div>

            <div className="toy-action">
              <div className="toy-action-item text-left">
                <a onClick={() => setOpen(false)}>
                  他のガチャを
                  <br />
                  探す <br />
                  <span>←</span>
                </a>
              </div>
              <div className="toy-action-item text-center m-auto">
                <a onClick={() => gacha(toyId)}>
                  <p>
                    ガチャを 引く
                    <br />
                    <span>↓</span>
                  </p>
                </a>
              </div>
              <div className="toy-action-item text-right">
                <a className="ml-auto" onClick={() => navigate("/user")}>
                  獲得一覧を
                  <br />
                  見る <br />
                  <span>→</span>
                </a>
              </div>
            </div>
          </div>
          <div class="toy-layer"></div>
          <div class="image-frame">
            {!loading && image ? <img src={image} alt="user ava" /> : null}
          </div>
          <img src={toy?.image} alt="Logo" />
        </>
      }
    />
  );
}

export default ToyDetalModal;
