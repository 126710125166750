import { React, memo, useState, useCallback, useEffect } from "react";
import { Html } from "@react-three/drei";
import YouTube from "react-youtube";
import { useAxes } from "../hooks/Axes";
import { usePosition } from '../hooks/Position';

function YoutubeAdsense(props) {


  const [isLite, setIsLite] = useState(true);
  const [position, getCurrent, getCurrentSilent, get_in] = usePosition();
  const onClick = useCallback((e) => {
    setIsLite(!isLite);
  }, []);

  const on_ready = useCallback((e) => {
    // console.log("ready : ", e);
    e.target.playVideo();
  }, []);
  const on_state_change = useCallback((e) => {
    // console.log("state change : ", e);
  }, []);
  const on_error = useCallback((e) => {
    // console.error("error : ", e);
  }, []);
  const opts = {
        height: props.h,
        width: props.w,
        playerVars: {
            muted: 1,
            playsinline: 1,
            autoplay: 1,
            controls: 1,
            'origin': window.location.protocol + '//' + window.location.hostname + "/"
        },
    }
  const [v, e] = useAxes();
  return (
    <Html
      key={Math.random() * 1000000}
      rotation={e(props.pitch, props.yaw, props.roll)}
      position={v(props.farness, props.height, props.how_far_right)}
      wrapperClass={"will-change "}
      as="div"
      zIndexRange={[10000, 0]}
      center
      transform
      sprite={props.alwaysLookAtCamera}
      style={{
        display: `${
          props.show 
            ? "inline"
            : "none"
        }`,
      }}
    >
      {isLite ? (
        <img
          src={`https://img.youtube.com/vi/${props.id}/maxresdefault.jpg`}
          width={props.w}
          height={props.h}
          className={`max-w-none max-h-none`}
          alt=""
          onClick={onClick}
        />
      ) : (
        <YouTube
          videoId={props.id}
          id={props.id}
          opts={opts}
          className={""}
          iframeClassName={""}
          title={""}
          loading="lazy"
          onReady={on_ready}
          onError={on_error}
          onStateChange={on_state_change}
        />
      )}
    </Html>
  );
}
export default memo(YoutubeAdsense);
